import React from "react"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const Politica = () => {

  return (
    <StyledPolitica>
      <p className={font.rSB + " title"}>
        <span className="smallUnderline"></span>
        POLÍTICA DE PRIVACIDADE
      </p>

      <p className={font.rR + ' txt'}>Adriano Carreira, Medicina Dentária, Lda., com sede na Rua José Afonso,
        Nº 2 e 4, Bloco B, Piso Zero 3800-438 Aveiro, com capital social de
        30.000,00 Euros(trinta mil euros), registado na Conservatória do Registo
        Comercial de Aveiro, com o número 510949002. Política de Privacidade
        Carreira Dental Clinic é responsável pelo Carreira Dental Clinic, adiante
        designado por site carreiradentalclinic.pt, e compromete-se a garantir a
        privacidade dos dados pessoais recolhidos e/ou transmitidos online. A
        segurança e a privacidade dos dados dos utilizadores do site
        carreiradentalclinic.pt são aspectos que assumem para a empresa uma
        importância crucial.
      </p>
      <p className={font.rR + ' txt'}>Por favor, leia o texto que se segue, para compreender e tomar
        conhecimento da forma como as informações que disponibilizar
        serão tratadas pela Carreira Dental Clinic. A Carreira Dental Clinic
        reserva-se o direito de alterar a política de privacidade, pelo que se
        aconselha a leitura regular deste documento. Estes textos
        reproduzem fielmente a política de privacidade seguida pelo site
        carreiradentalclinic.pt. </p>
      <p className={font.rSB}>No presente documento, poderá encontrar a resposta às
        seguintes questões: </p>
      <p className={font.rR + ' txt'}><span className={font.rB}>1 |</span> Qual é a informação que é recolhida sobre os utilizadores?</p>
      <p className={font.rR + ' txt'}><span className={font.rB}>2 |</span> Qual é a entidade responsável pela recolha da informação?</p>
      <p className={font.rR + ' txt'}><span className={font.rB}>3 |</span> O que são cookies e como é que a Carreira Dental Clinic os utiliza?</p>
      <p className={font.rR + ' txt'}><span className={font.rB}>4 |</span> Como é que a informação recolhida é utilizada?</p>
      <p className={font.rR + ' txt'}><span className={font.rB}>5 |</span> Que procedimentos de segurança tem o site Carreira Dental Clinic, que garantam ao utilizador a protecção dos seus dados contra o acesso, a alteração ou a destruição não autorizada?</p>
      <p className={font.rR + ' txt'}><span className={font.rB}>6 |</span> De que forma pode o utilizador corrigir ou eliminar as informações que facultou previamente através do website Carreira Dental Clinic?</p>
      <p className={font.rR + ' txt'}><span className={font.rB}>7 |</span> Outras questões de que deverá ter conhecimento sobre a política de privacidade da Carreira Dental Clinic.</p>
      <p className={font.rSB}>Se tiver qualquer dúvida sobre alguns dos pontos aqui
        apresentados, agradecemos que nos comunique por email
        para ou por correio para Carreira Dental Clinic, Rua José
        Afonso, Nº 2 e 4, Bloco B, Piso Zero 3800-438 Aveiro. </p>
      <p className={font.rR + ' txt'}><span className={font.rB}>1 | Qual é a informação que é recolhida sobre os utilizadores?</span><br />
        Para que se possa tornar um subscritor da nossa newsletter
        através do website carreiradentalclinic.pt é necessário proceder ao
        preenchimento do formulário que se encontra disponível online. A
        recolha dos dados pessoais é efectuada no momento do
        preenchimento do formulário. A informação recolhida é a
        necessária para assegurar a identidade do utilizador. No acto de
        registo, a informação que o utilizador disponibiliza tem por objectivo
        a prestação de um serviço mais adequado às suas características
        e necessidades e ao envio de informações relacionadas com os
        serviços prestados pela Carreira Dental Clinic, tais como: dicas de
        saúde oral, eventos ou informações gerais. O leitor poderá
        actualizar as informações por si fornecidas sempre que quiser
        bastando que para esse efeito, envie o pedido para
        geral@carreiradentalclinic.pt.
        <br /> As operações de fornecimento de dados pessoais estão
        devidamente protegidas. Toda a informação é encriptada e gerida
        com as mais avançadas ferramentas de segurança. </p>
      <p className={font.rR + ' txt'}><span className={font.rB}>2 | Qual é a entidade responsável pela recolha da informação?</span><br />
        A Carreira Dental Clinic é a única responsável pela recolha de
        dados pessoais efectuada no site carreiradentalclinic.pt. Sempre
        que o utilizador termine a utilização deste serviço, a Carreira Dental
        Clinic não assume qualquer responsabilidade pela informação
        recolhida fora das respectivas páginas electrónicas.</p>
      <p className={font.rR + ' txt'}><span className={font.rB}>3 | O que são cookies e como é que Carreira Dental Clinic os
        utiliza?</span><br />
        Para poder prestar um serviço mais personalizado e ao agrado dos
        utilizadores, a Carreira Dental Clinic utiliza cookies para recolher e
        guardar informação. Um cookie é um pequeno ficheiro informativo
        que é enviado do servidor do site www.carreiradentalclinic.pt para o
        browser do utilizador e armazenado no respectivo computador.
        Estes cookies serão apenas utilizados pela Carreira Dental Clinic e
        a sua utilização limita-se às seguintes finalidades: – permitir a
        Carreira Dental Clinic saber quem é o utilizador e, dessa forma,
        prestar um serviço melhor, mais seguro e personalizado; – estimar
        os níveis de utilização dos serviços. A cada utilizador corresponde
        um cookie individual, garantindo desta forma a privacidade e a
        segurança dos dados. A apresentação dos dados estatísticos é
        feita de uma forma agregada e não individual, não permitindo a
        identificação do utilizador. Desta forma, é possível avaliar o
        desempenho do site carreiradentalclinic.pt numa perspectiva de
        atualização e melhoria constantes, para que se possa satisfazer os
        gostos e as necessidades do utilizador. </p>
      <p className={font.rR + ' txt'}><span className={font.rB}>4 | Como é que a informação recolhida é utilizada?</span><br />
        A informação recolhida servirá apenas para os seguintes
        propósitos: – elaboração de estatísticas que permitam perceber
        como a Carreira Dental Clinic poderá melhorar o nível de serviços
        que presta e, dessa forma, corresponder mais eficazmente às
        expectativas dos utilizadores; – aumentar o nível de personalização
        dos serviços para que se adaptem, na maior medida possível, aos
        gostos e preferências dos utilizadores. Os dados pessoais
        fornecidos pelo utilizador serão utilizados exclusivamente para fins
        ligados à execução do respectivo contrato, bem como, se o
        utilizador assim o autorizar, para actividades de informação,
        marketing e telemarketing da Carreira Dental Clinic. As
        comunicações de dados serão feitas mediante prévio
        consentimento dos titulares dos dados. Carreira Dental Clinic não
        procederá à venda, aluguer, partilha ou oferta dos dados fornecidos
        pelos seus leitores, excepto nas situações previstas na presente
        política de privacidade.
      </p>
      <p className={font.rR + ' txt'}><span className={font.rB}>5 | Que procedimentos de segurança tem o site Carreira Dental
        Clinic que garanta aos utilizadores a protecção dos seus
        dados contra o acesso, a alteração ou a destruição não
        autorizada?</span><br />
        A Carreira Dental Clinic toma todas as precauções necessárias e
        legalmente exigidas para garantir a protecção da informação
        recolhida junto dos seus utilizadores através do site Carreira Dental
        Clinic. Estas precauções garantem a segurança online e offline
        dessa informação. Se tiver alguma questão a colocar-nos sobre a
        segurança e privacidade que a Carreira Dental Clinic lhe garante,
        entre em contacto para +351 234 423 042 / +351 220 925 530 /
        +351 234 384 992 / +351 232 418 923 / +351 256 131 702 ou por
        correio para Carreira Dental Clinic, Rua José Afonso, Nº 2 e 4,
        Bloco B, Piso Zero 3800-438 Aveiro.</p>
      <p className={font.rR + ' txt'}><span className={font.rB}>6 | De que forma pode o utilizador corrigir ou eliminar as
        informações que facultou previamente nas aplicações da
        Carreira Dental Clinic?</span><br />
        No âmbito das condições de utilização do site carreiradentalclinic.pt
        e de todos os seus serviços, o utilizador compromete-se a fornecer
        e a manter atualizados e verdadeiros todos os seus dados
        pessoais. Por sua vez, a Carreira Dental Clinic, garante ao
        utilizador a possibilidade de corrigir e actualizar os seus dados,
        devendo o utilizador contactar a Carreira Dental Clinic por e-mail
        para geral@carreiradentalclinic.pt ou por correio, para a Rua José
        Afonso, Nº 2 e 4, Bloco B, Piso Zero 3800-438 Aveiro. Todos os
        utilizadores poderão, através do website www.carreiradentalclinic.pt
        subscrever newsletters. Em todas as newsletters enviadas aos
        subscritos do website Carreira Dental Clinic têm a opção de
        cancelamento da subscrição.
      </p>
      <p className={font.rR + ' txt'}><span className={font.rB}>7 | Outras questões de que deverá ter conhecimento sobre a
        política de privacidade do website Carreira Dental Clinic: </span></p>


      <p className={font.rR + ' txt'}><span className={font.rM}>– Alteração de Condições de Utilização do site da Carreira Dental
        Clinic</span><br />
        Sempre que houver uma alteração das condições de utilização do
        site carreiradentalclinic.pt, designadamente nas Condições de
        Serviço ou na Política de Privacidade, a Carreira Dental Clinic
        enviará um email aos seus utilizadores.
      </p>
      <p className={font.rR + ' txt'}><span className={font.rM}>– Autorização para Utilizações Diversas</span><br />
        Se a Carreira Dental Clinic pretender utilizar os dados dos seus
        utilizadores para finalidades distintas daquelas para as quais
        recebeu autorização inicial dos utilizadores, solicitará uma nova
        autorização dos utilizadores para essa utilização específica. </p>
    </StyledPolitica>
  )
}

export default Politica

const StyledPolitica = styled.div`
  position: relative;
  padding: 0 25% 5em;
  .txt{
    font-size: calc(12px + 10 * (100vw - 769px) / 1151);
      @media only screen and (min-width: 1920px){
        font-size: 22px;
      }
      letter-spacing: 0.1em;
      line-height: 1.6em;
      color: #808080;
      white-space: pre-wrap;
  }
  .title{
    font-size:calc(22px + 4 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px){
      font-size: 26px;
    }
    letter-spacing: 0.1em;
    color: #231f20;
    position: relative;
    margin-bottom: 2em;
    .smallUnderline{
      width: 2em;
      height: 0.25em;
      background-color: #b7a99a;
      position: absolute;
      top: 1.6em;
      left: 0;
    }
  }
  p{
    font-size: calc(12px + 12 * (100vw - 300px) / 1620);
    line-height: calc(22px + 11 * (100vw - 300px) / 1620);
    margin: 2em 0;
    color: #707070;
  }
  @media only screen and (max-width: 1200px){
    padding: 0 18% 5em;
  }
  @media only screen and (max-width: 696px){
    .title{
      .smallUnderline{
        top: 3em;
      }
    }
  }
  @media only screen and (max-width: 500px){
    .title{
      .smallUnderline{
        top: 3.5em;
      }
    }
  } 
`