import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Navbar from "../components/navbar/navbar"
import NavbarMobile from "../components/navbar/navbarMobile"
import Politica from "../components/politica/politica"
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"

const PoliticaPage = ({data}) => (
  <Layout>
    <SEO title="Política de Privacidade | Carreira Dental Clinic" />
    {useBreakpoint().mobile ? 
      <>
        <NavbarMobile/>
        <Politica/>
        <FooterMobile data={data.globalJson.footer}/>
      </>
      :
      <>
        <Navbar/>
        <Politica/>
        <Footer data={data.globalJson.footer}/>
      </>
    }
  </Layout>
)

export default PoliticaPage

export const Json = graphql`
  query politica {
    globalJson{
      footer{
        newsletter{
          subscreva
          btnTxt
        }
        aveiro{
          title
          morada
          contacto
        }
        agueda{
          title
          morada
          contacto
        }
        porto{
          title
          morada
          contacto
        }
        viseu{
          title
          morada
          contacto
        }
        oliveira{
          title
          morada
          contacto
        }
        email
        schedule
        bg{
          publicURL
        }
      }
    }
  }
`